.cardNav{
    height:80px;
    text-align:left;
    background:#8dc7fd;
    border-radius:5px;
    padding-top: 10px;
}
.cardBody{
    margin-left: 20px;
    color:#ffffff;
    font-size: 36px;
    font-weight: 500;
}
.echartsize{
    width: 85%;
    height: 480px;
    margin: 0;
}