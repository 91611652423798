.ant-layout-content {
  -ms-flex: auto;
  flex: none;
  min-height: 0;
}
 .logo {
    text-align: center;
    height: 32px;
    margin: 16px;
  }
   .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .trigger:hover {
    color: #1890ff;
  }
  .logocenter{
    text-align: center;
    /* display: inline-block; */
  }
  .logotext{
    color: white;
    float: right;
    margin-top: 10px;
    font-size: 1rem;
    width: min-content;
    margin-right: 48px;
  }
  .logotext p{
    display: inline;
  }
  .logotext span{
    display: inline-block;
  }
  html,body,#root{
    height: 100%;
}
.ant-layout{
    height: 100%;
} 
.footerpos{
  text-align: center;
  width: 100%;
  position: fixed;
  bottom: 0;
}
.siderpos{
  width: 100%;
}